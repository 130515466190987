import React, { useMemo } from 'react';

// Components
import HomePageComponent from '../modules/homepage';

// data
import { graphql } from 'gatsby';
import { defaultLanguage } from '../../prismic-config';

// utils
import withTranslate from '../utils/withTransWrapper';
import SEO from '../components/seo';

const Landing = ({ data }) => {
  const dataInSelectedLang = useMemo(() => {
    const lang =
      typeof window !== 'undefined'
        ? window.localStorage.getItem('language') ?? defaultLanguage
        : defaultLanguage;
    return data['allPrismicLanding'].edges.find((edge) => edge.node.lang === lang).node.data;
  }, [data]);

  return (
    <>
      <SEO title="20Scoops" />
      <HomePageComponent data={dataInSelectedLang} />
    </>
  );
};

export default withTranslate(Landing);

export const query = graphql`
  query {
    allPrismicLanding {
      edges {
        node {
          data {
            banner_description {
              raw
            }
            banner_title {
              raw
            }
            body {
              ... on PrismicLandingDataBodyContentSecText {
                id
                items {
                  comapny_title {
                    raw
                  }
                  company_content {
                    raw
                  }
                  text_animation {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLandingDataBodyContentSecImage {
                id
                items {
                  content_description {
                    raw
                  }
                  content_image {
                    fluid(imgixParams: { q: 50, maxHeight: 1000, maxWidth: 1600 }) {
                      src
                    }
                  }
                  content_title {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLandingDataBodyImage {
                id
                slice_type
                items {
                  image_animation {
                    alt
                    url
                  }
                }
              }
              ... on PrismicLandingDataBodyContentDialog {
                id
                items {
                  dialog_icon {
                    fluid {
                      src
                    }
                  }
                  icon_description {
                    raw
                  }
                }
                slice_type
                primary {
                  dialog_content {
                    raw
                  }
                  dialog_title {
                    raw
                  }
                  image_banner {
                    fluid {
                      src
                    }
                  }
                  offer_contact_dialog {
                    raw
                  }
                  offer_dialog_title {
                    raw
                  }
                  offer_dialog_description {
                    raw
                  }
                  offer_notice_dialog {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLandingDataBodyCounterWorker {
                id
                items {
                  amount_position {
                    text
                  }
                  icon_image {
                    fluid {
                      src
                    }
                  }
                  name_position {
                    raw
                  }
                }
              }
              ... on PrismicLandingDataBodyCardSection {
                id
                items {
                  card_content {
                    raw
                  }
                  card_cradit {
                    raw
                  }
                  name_card {
                    raw
                  }
                }
              }
            }
          }
          lang
        }
      }
    }
  }
`;

import React from 'react';
import { Suspense } from 'react';
import { lazy } from '@loadable/component';

const Desktop = lazy(() => import('./desktop'));

const Homepage = ({ data }) => {
  if (typeof window === 'undefined')
    return (
      <div
        css={`
          width: 100vw;
          height: 100vh;
          background: #ffffff;
        `}
      />
    );

  return (
    <>
      <Suspense
        fallback={
          <div
            css={`
              width: 100vw;
              height: 100vh;
              background: #ffffff;
            `}
          />
        }
      >
        <Desktop data={data} />
      </Suspense>
    </>
  );
};

export default Homepage;
